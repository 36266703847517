<template>
    <div>
        模糊查询<el-input v-model="onlyText" style="width:200px" @change="getList"></el-input>
        <el-table :data="tableData" v-loading="tableLoading">
            <!-- <el-table-column label="标题" prop="title" width="100" ></el-table-column> -->
            <el-table-column label="插入数据库时间" prop="insertTime" width="150"></el-table-column>
            <el-table-column label="内容" prop="receiveContent"></el-table-column>
            <el-table-column label="设备记录时间" :formatter="handleFormatter"></el-table-column>
        </el-table>
        <el-pagination @current-change="getList" background :total="total" :page-size="pageSize"
            :current-page.sync="currentPage"></el-pagination>
    </div>
</template>

<script>
import moment from "moment";
export default {
    data() {
        return {
            tableData: null,
            pageSize: 10,
            currentPage: 1,
            total: 0,
            tableLoading: false,
            onlyText: "",
        };
    },
    methods: {
        handleFormatter(row) {
            if (row.receiveContent.length == 67) {
                let tmp = eval("0x" + row.receiveContent.substr(20, 8)).toString();
                let res = moment("1970-01-01").add(tmp, "s").format("yyyy-MM-DD HH:mm:ss");
                return res;
            }
            else { return "" }
            //return row.receiveContent.substr(20, 8);
        },
        getList() {
            let that = this;
            that.tableLoading = true;
            that.axios
                .post("RiZhi_ReceiveByte/GetList", {
                    onlyText: that.onlyText,
                    currentPage: that.currentPage,
                    pageSize: that.pageSize,
                })
                .then(function (response) {
                    console.log(response);
                    that.total = response.data.data.total;
                    that.tableData = response.data.data.data;
                    that.tableLoading = false;
                });
        },
    },
    mounted() {
        let that = this;
        that.getList();
    },
};
</script>

<style></style>